import {
    Callout,
    DelayedRender,
    IconButton,
    mergeStyleSets,
    useTheme,
    DirectionalHint,
    IButtonStyles, FontSizes, StackItem, Stack, TextField, PrimaryButton, DefaultButton, mergeStyles, Spinner
} from '@fluentui/react';
import { FunctionComponent, useEffect, useState } from 'react';
import { Modal, SanitizedText } from 'components';
import { Target, useBoolean, useId } from '@fluentui/react-hooks';
import { useIntl } from "react-intl";
import { useUpdateClientPortalTabComment } from '../../hooks/useUpdateClientPortalTabComment';
import { Editor, EditorState } from 'draft-js';
import { convertFromHTML } from 'draft-convert';

interface IClientPortalTabCommentProps {
    comment?: string;
    itemId: number;
    disabled: boolean;
    jobId: number;
}

interface IClientPortalTabCommentPreviewProps {
    comment: string;
    target?: Target;
    onPointerEnter: () => void;
    onPointerLeave: () => void;
}


const ClientPortalTabCommentPreview: FunctionComponent<IClientPortalTabCommentPreviewProps> = ({
   comment, onPointerEnter, onPointerLeave, target,
}) => {

    const { formatMessage } = useIntl();
    const theme = useTheme();

    const styles = mergeStyleSets({
        header: {
        },
        headerText: {
            fontWeight: "bold",
        },
        callout: {
            minWidth: 350,
            maxWidth: 500,
            padding: '20px 24px',
        },
    });


    return (
        <>
            <Callout className={styles.callout}
                target={`#${target}`}
                onPointerEnter={() => onPointerEnter?.()}
                onPointerLeave={() => onPointerLeave?.()}
                onDismiss={() => { }}
                gapSpace={3}
                role="alert"
                directionalHint={DirectionalHint.rightCenter}>
                        <Stack grow tokens={{ childrenGap: 16 }}>
                            <StackItem>
                                <SanitizedText color={theme.schemes?.default?.semanticColors.bodyText} data={comment} />
                            </StackItem>
                        </Stack>
            </Callout>
        </>
    );
};

interface IClientPortalTabCommentFormProps {
    itemId: number;
    data?: string;
    disabled: boolean;
    isUpdating: boolean;
    onSave: (newDate?: string) => void;
    onCancel: Function;
}

interface IClientPortalTabCommentFormState {
    value: EditorState;
}

export const ClientPortalTabCommentForm: FunctionComponent<IClientPortalTabCommentFormProps> = ({
    data,
    disabled,
    isUpdating,
    onSave,
    onCancel,
    ...otherProps
}: IClientPortalTabCommentFormProps) => {
    const { formatMessage } = useIntl();
    const theme = useTheme();

    const [formState, setFormState] = useState<IClientPortalTabCommentFormState>({
        value: EditorState.push(EditorState.createEmpty(), convertFromHTML(data ?? ''), 'insert-characters'),
    });

    const onSaveClick = () => {
        onSave(formState.value.getCurrentContent().getPlainText())
    }

    const className = mergeStyles({
        selectors: {
            '.public-DraftEditor-content': {
                minHeight: 120
            },
        },
    });

    return (
        <Stack tokens={{ childrenGap: 16 }} className={className}>
            <Stack.Item theme={theme?.schemes?.default}>
                <Stack styles={{
                    root: {
                        border: `1px solid ${disabled ? theme.palette.neutralSecondaryAlt : theme.palette.neutralPrimary}`,
                        borderRadius: 2,
                        overflow: 'hidden',
                        padding: 8,
                        color: theme.schemes?.default?.semanticColors.bodyText
                    }
                }}>
                    <Editor
                        editorState={formState.value}
                        onChange={(v) => setFormState(prev => ({ ...prev, value: v }))}
                        readOnly={disabled || isUpdating}
                    />
                </Stack>
            </Stack.Item>
            <Stack.Item>
                <Stack horizontal horizontalAlign='end' tokens={{ childrenGap: 16 }}>
                    {isUpdating && <Spinner />}
                    <PrimaryButton text={formatMessage({ id: 'save' })}
                        onClick={onSaveClick}
                        disabled={disabled || isUpdating}
                    />
                    <DefaultButton onClick={() => onCancel()}
                        text={formatMessage({ id: 'cancel' })}
                    />
                </Stack>
            </Stack.Item>
        </Stack>
    )
}

export const ClientPortalTabComment: FunctionComponent<IClientPortalTabCommentProps> = ({ comment, itemId, disabled, jobId }) => {
    const [isOpenModal, { toggle: toggleOpenModal }] = useBoolean(false);
    const theme = useTheme();
    const { formatMessage } = useIntl();
    const [isPreviewVisible, { toggle: toggleIsPreviewVisible }] = useBoolean(false);
    const buttonId = useId('callout-button');

    const [isPointerOverPreview, setIsPointerOverPreview] = useState<boolean>(false);
    const { update, isUpdating } = useUpdateClientPortalTabComment();

    const styles: IButtonStyles = {
        root: {
            color: comment ? theme.palette.red : theme.palette.themePrimary,
        },
        rootHovered: {
            color: comment ? theme.palette.redDark : theme.palette.themeDarker
        },
        rootPressed: {
            color: comment ? theme.palette.red : theme.palette.themePrimary,
        }
    }

    const handlePointerEnter = () => {
        setIsPointerOverPreview(true);
    };

    const handlePointerLeave = () => {
        setIsPointerOverPreview(false);
    };

    const onSave = (newComment?: string) => {
        if (newComment === comment) {
            return;
        }

        update({
            jobId: jobId,
            tabCommentId: itemId,
            comment: newComment
        }, {
            onSuccess: () => {
                toggleOpenModal()
            }
        })
    }

    useEffect(() => {
        if (!isPointerOverPreview && isPreviewVisible) {
            toggleIsPreviewVisible()
        }
    }, [isPointerOverPreview])

    return (
        <>
            <IconButton id={buttonId} 
                iconProps={{ iconName: 'Comment', style: { fontSize: FontSizes.size14 } }}
                onPointerEnter={toggleIsPreviewVisible}
                onPointerLeave={() => {
                    setTimeout(() => {
                        if (!isPointerOverPreview) {
                            toggleIsPreviewVisible()
                        }
                    }, 800);
                }}
                styles={styles}
                onClick={toggleOpenModal} />
            {isPreviewVisible && comment && (<ClientPortalTabCommentPreview
                comment={comment}
                target={buttonId}
                onPointerEnter={handlePointerEnter}
                onPointerLeave={handlePointerLeave}
            />)}
            <Modal isOpen={isOpenModal} isBlocking={true} isDraggable={true} onDismiss={toggleOpenModal} title={formatMessage({ id: 'comments' })}>
                <ClientPortalTabCommentForm
                    data={comment}
                    itemId={itemId}
                    onCancel={toggleOpenModal}
                    onSave={onSave}
                    isUpdating={isUpdating}
                    disabled={disabled}>
                </ClientPortalTabCommentForm>
            </Modal>
        </>
    );
};