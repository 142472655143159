import { FunctionComponent } from 'react';
import { DefaultButton, IButtonProps, PrimaryButton, Stack } from '@fluentui/react';

import { DisabledChildren } from '../../components';

interface IPermanentDocumentTogglerProps {
    isPermanent: boolean;
    disabled: boolean;
    toggle: (isPermanent: boolean) => void;
}

export const PermanentDocumentToggler: FunctionComponent<IPermanentDocumentTogglerProps> = ({ isPermanent, disabled, toggle }) => {

    const btnProps: Partial<IButtonProps> = {
        minLength: 100,
        styles: { root: { borderTopRightRadius: 0, borderBottomRightRadius: 0 } },
    };

    const markButtonRender = (): JSX.Element => isPermanent
        ? (<PrimaryButton
            toggle
            primary={true}
            minLength={btnProps.minLength}
            styles={btnProps.styles}
            checked={true}
            text="Marked"
            onClick={() => toggle(true)}
        />)
        : (<DefaultButton toggle
            primary={false}
            minLength={btnProps.minLength}
            styles={btnProps.styles}
            checked={false}
            text="Mark"
            onClick={() => toggle(true)}
        />);

    const unmarkButtonRender = (): JSX.Element => isPermanent
        ? (<DefaultButton
            toggle
            minLength={btnProps.minLength}
            styles={btnProps.styles}
            checked={false}
            text="Unmark"
            onClick={() => toggle(false)}
        />)
        : (<PrimaryButton
            toggle
            minLength={btnProps.minLength}
            styles={btnProps.styles}
            checked={true}
            text="Unmarked"
            onClick={() => toggle(false)}
        />);

    return (
        <Stack horizontal styles={{ root: { width: '100%' } }}>
            <DisabledChildren disabled={!!disabled}>
                {markButtonRender()}
                {unmarkButtonRender()}
            </DisabledChildren>
        </Stack>
    );
};

