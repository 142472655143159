import {FunctionComponent, useCallback, useEffect, useMemo} from 'react';
import {
    Checkbox,
    IconButton,
    IDropdownOption,
    mergeStyleSets,
    MessageBarType,
    Stack,
    Text,
    useTheme
} from '@fluentui/react';
import {useIntl} from 'react-intl';
import {
    CalendarAnswer,
    DropdownAnswer,
    OrderSearchButton,
    TextBoxAnswer
} from 'pages/JobPortal/components/templates/answers';
import {ControlledDatePicker, ControlledDropdown, ControlledTextField, SanitizedText} from 'components';
import {
    Attachment,
    ProceduresDataTable,
    useProcedureContext,
    JobComment,
    PermanentCheckBox
} from 'pages/JobPortal/components';
import {useFieldArray, useForm} from "react-hook-form";
import {IPropertyDetailItem} from "./interfaces";
import {DefaultFormSettings} from "../../../../../../constants";
import {useCreatePropertyDetail, useUpdatePropertyDetail} from "./hooks";
import {NotificationType, useSignalR} from "../../../../../../providers";
import {useNotifications} from "../../../../../../components/notifications";
import {useJobContext} from "../../../../JobPortalLayoutPage";
import {TableType} from "../../../../../../enums";
import {useTabContext} from "../../../../JobPortalPage";
import { PropertyDetailsOrderStatus } from './enums';

interface IDisabledCell {
    text?: string;
}

const ProperyDetailsDisabledCell: FunctionComponent<IDisabledCell> = ({
                                                                          text
                                                                      }: IDisabledCell) => {
    const theme = useTheme();

    return (
        <Text styles={{root: {color: theme.schemes?.default?.semanticColors.disabledBodyText}}}>{text}</Text>
    )
}

export const PropertyDetailsProcedureTemplate: FunctionComponent = () => {
    const {items, isLoading, refresh} = useProcedureContext();
    const {formatMessage} = useIntl();
    const {useSignalREffect} = useSignalR();
    const {showNotification} = useNotifications();
    const {jobYear} = useJobContext();
    const {isTabEnabled} = useTabContext();

    const {create, isCreating} = useCreatePropertyDetail();
    const {update, isUpdating} = useUpdatePropertyDetail();
    const {control, setValue, getValues} = useForm<{ items: IPropertyDetailItem[] }>({
        ...DefaultFormSettings,
        mode: 'onChange',
        defaultValues: {items: items}
    });

    const handleOnChange = (index: number) => {
        const item = getValues(`items.${index}`);
        if (item.id)
            update(item);
        else {
            create(item,
                {
                    onSuccess: (data) => {
                        setValue(`items.${index}`, data);
                    }
                });
        }
    };

    const isChanging = useMemo(() => isCreating || isUpdating, [isCreating, isUpdating]);
    useSignalREffect("orderSearch_completed", (notification: NotificationType) => {

        if (notification.content.report?.content) {
            const binaryString = window.atob(notification.content.report.content);
            const binaryLen = binaryString.length;
            let bytes = new Uint8Array(binaryLen);
            for (let i = 0; i < binaryLen; i++) {
                const ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            let FileSaver = require('file-saver');
            let blob = new Blob([bytes], { type: notification.content.report.type });
            FileSaver.saveAs(blob, notification.content.report.fileName);
            showNotification({
                name: formatMessage({ id: 'orderSearch' }),
                description: formatMessage({ id: 'orderSearch_notification_completed' }),
                type: MessageBarType.success
            });
        }

        refresh?.();
    }, [showNotification]);

    useSignalREffect("orderSearch_error", (notification: NotificationType) => {
        showNotification({
            name: formatMessage({id: 'orderSearch'}),
            description: notification.content.error,
            type: MessageBarType.error
        });
    }, [showNotification]);

    const isOrderSearchButtonLocked = useCallback((state?: number, isSighted?: number, date?: Date, title?: string, folio?: string, volume?: string): boolean => {
        const limit = new Date(jobYear - 1, 11, 31);
        if ((state ?? false) && (isSighted ?? false)) {
            if (state! > 0 &&
                ((state! < 3 && title) || (state! > 2 && volume && folio)) &&
                ((isSighted === 1) || (isSighted === 2 && date && date.valueOf() < limit.valueOf()))
                // check roles
                //&& (fw.config.role == 'BasicUser' ||
                //                 fw.config.role == 'PowerUser' ||
                //                 fw.config.role == 'Standard' ||
                //                 fw.config.role == 'ReviewerUser' ||
                //                 fw.config.role == 'Administrator' ||
                //                 fw.config.role == 'CPAdministrator' ||
                //                 fw.config.role == 'SuperAdministrator' ||
                //                 fw.config.role == 'AuditorCPUser' ||
                //                 fw.config.role == 'SuperUser')
            ) {
                return false;
            }
        }

        return true;
    }, []);

    return (
        <ProceduresDataTable
            items={items}
            isLoading={isLoading}
            header={{
                horizontalAlign: 'center',
                rowHeight: 80
            }}
            columns={[
                {
                    key: 'actions',
                    name: formatMessage({id: 'actions'}),
                    minWidth: 80,
                    maxWidth: 80,
                    fieldName: 'actions',
                    // for Property Search procedure Order field is used as Attachment.ItemId
                    onRender: (items) => (
                        <Stack horizontal>
                            <Attachment itemId={items?.order} hasAttachments={items?.hasAttachments}
                                        tableType={items?.tableType}/>
                            <JobComment itemId={items?.order} hasComments={items?.hasComments}
                                        tableType={items?.tableType}/>
                        </Stack>
                    ),
                },
                {
                    key: 'state',
                    name: formatMessage({id: 'state'}),
                    minWidth: 120,
                    maxWidth: 120,
                    fieldName: 'state',
                    onRender: (items, index) => index !== undefined ?
                        <DropdownAnswer value={getValues(`items.${index}.state`)}
                                        onChange={(answer) => {
                                            if (!!getValues(`items.${index}.id`) && answer === getValues(`items.${index}.state`)) {
                                                return;
                                            }

                                            setValue(`items.${index}.state`, answer);
                                            handleOnChange(index);
                                        }}
                                        disabled={isChanging}
                                        options={items?.stateOptions?.map((x: any) => ({
                                            key: Number(x.value),
                                            text: x.field
                                        }))}/> : null,
                },
                {
                    key: 'titleReference',
                    name: formatMessage({id: 'titleReference'}),
                    minWidth: 120,
                    fieldName: 'titleReference',
                    onRender: (item, index, column) => {
                        if (index === undefined) {
                            return null;
                        }

                        if (getValues(`items.${index}.state`) <= 2) {
                            return (
                                <TextBoxAnswer
                                    value={getValues(`items.${index}.titleReference`)}
                                    onChange={(answer) => {
                                        if (!!getValues(`items.${index}.id`) && answer === getValues(`items.${index}.titleReference`)) {
                                            return;
                                        }
                                        setValue(`items.${index}.titleReference`, answer);
                                        handleOnChange(index);
                                    }}
                                    disabled={isChanging}
                                />
                            );
                        }
                        return (
                            <ProperyDetailsDisabledCell text={getValues(`items.${index}.titleReference`)}/>
                        )
                    }
                },
                {
                    key: 'volume',
                    name: formatMessage({id: 'volume'}),
                    minWidth: 100,
                    maxWidth: 100,
                    fieldName: 'volume',
                    onRender: (items, index) => {
                        if (index === undefined) {
                            return null;
                        }

                        const state = getValues(`items.${index}.state`);
                        if (state > 2 || state === 0) {
                            return (
                                <TextBoxAnswer value={getValues(`items.${index}.volume`)} onChange={(answer) => {
                                    if (!!getValues(`items.${index}.id`) && answer === getValues(`items.${index}.volume`)) {
                                        return;
                                    }
                                    setValue(`items.${index}.volume`, answer);
                                    handleOnChange(index);
                                }}
                                               disabled={isChanging}
                                />
                            )
                        }
                        return (
                            <ProperyDetailsDisabledCell text={getValues(`items.${index}.volume`)}/>
                        )
                    }
                },
                {
                    key: 'folio',
                    name: formatMessage({id: 'folio'}),
                    minWidth: 100,
                    maxWidth: 100,
                    fieldName: 'folio',
                    onRender: (items, index) => {
                        if (index === undefined) {
                            return null;
                        }

                        const state = getValues(`items.${index}.state`);
                        if (state > 2 || state === 0) {
                            return (
                                <TextBoxAnswer value={getValues(`items.${index}.folio`)} onChange={(answer) => {
                                    if (!!getValues(`items.${index}.id`) && answer === getValues(`items.${index}.folio`)) {
                                        return;
                                    }
                                    setValue(`items.${index}.folio`, answer);
                                    handleOnChange(index);
                                }}
                                               disabled={isChanging}
                                />
                            )
                        }
                        return (
                            <ProperyDetailsDisabledCell text={getValues(`items.${index}.folio`)}/>
                        )
                    }
                },
                {
                    key: 'titleSearchSighted',
                    name: formatMessage({id: 'titleSearchSighted'}),
                    minWidth: 70,
                    maxWidth: 70,
                    fieldName: 'titleSearchSighted',
                    onRender: (items, index) => index !== undefined ?
                        <DropdownAnswer value={getValues(`items.${index}.titleSearchSighted`)}
                                        disabled={isChanging}
                                        onChange={(answer) => {
                                            if (!!getValues(`items.${index}.id`) && answer === getValues(`items.${index}.titleSearchSighted`)) {
                                                return;
                                            }
                                            setValue(`items.${index}.titleSearchSighted`, answer);
                                            handleOnChange(index);
                                        }}
                                        options={items.titleSearchSightedOptions.map((x: any) => ({
                                            key: +x.value, text: x.field
                                        }))}/> : null,
                },
                {
                    key: 'dateofTitleSearch',
                    name: formatMessage({id: 'dateOfTitleSearch'}),
                    minWidth: 120,
                    maxWidth: 120,
                    fieldName: 'dateofTitleSearch',
                    onRender: (items, index) => {
                        if (index === undefined) {
                            return;
                        }

                        return (
                            <ControlledDatePicker
                                disabled={isChanging || !isTabEnabled} onDateSelected={(date) => {
                                if (!!getValues(`items.${index}.id`) && (date?.toDateString() ?? '') === getValues(`items.${index}.dateofTitleSearch`)) {
                                    return;
                                }
                                setValue(`items.${index}.dateofTitleSearch`, date?.toDateString() ?? '');
                                handleOnChange(index);
                            }}
                                control={control} name={`items.${index}.dateofTitleSearch`}/>
                        )
                    }
                },
                {
                    key: 'orderSearch',
                    name: '',
                    minWidth: 110,
                    maxWidth: 110,
                    fieldName: 'orderSearch',
                    onRender: (item: IPropertyDetailItem, index) => {
                        if (index === undefined) {
                            return null;
                        }

                        const title = getValues(`items.${index}.titleReference`);
                        const volume = getValues(`items.${index}.volume`);
                        const folio = getValues(`items.${index}.folio`);
                        const state = getValues(`items.${index}.state`);
                        const isSighted = getValues(`items.${index}.titleSearchSighted`)
                        const date = getValues(`items.${index}.dateofTitleSearch`)

                        const isOrderCompletedOrOrdered = item.orderStatus === PropertyDetailsOrderStatus.Completed || item.orderStatus === PropertyDetailsOrderStatus.Ordered;

                        return (
                            <>
                                <OrderSearchButton
                                    key={`order-search-btn-${index}`}
                                    itemId={item.order}
                                    titleReference={title}
                                    disabled={isChanging || isOrderCompletedOrOrdered || isOrderSearchButtonLocked(state, isSighted, date ? new Date(date) : undefined, title, folio, volume)}
                                    volume={volume}
                                    folio={folio}
                                    tableType={TableType.PropertyDetails}
                                    propertyState={state}/>
                            </>
                        )
                    }
                },
                {
                    key: 'orderStatus',
                    name: formatMessage({id: 'orderStatus'}),
                    minWidth: 100,
                    maxWidth: 100,
                    fieldName: 'orderStatus',
                    onRender: (items, index) => index !== undefined ?
                        <DropdownAnswer value={getValues(`items.${index}.orderStatus`)}
                                        onChange={(answer) => {
                                            if (!!getValues(`items.${index}.id`) && answer === getValues(`items.${index}.orderStatus`)) {
                                                return;
                                            }
                                            setValue(`items.${index}.orderStatus`, answer);
                                            handleOnChange(index);
                                        }}
                                        disabled={true}
                                        options={items.orderStatusOptions?.map((x: any) => ({
                                            key: Number(x.value),
                                            text: x.field
                                        }))}/> : null,
                },
                {
                    key: 'permanent',
                    name: formatMessage({id: 'permanent'}),
                    minWidth: 80,
                    maxWidth: 80,
                    fieldName: 'permanent',
                    onRender: (items) => (
                        <Stack verticalAlign='center' horizontalAlign='center' grow={1}>
                            <PermanentCheckBox itemId={items.id} checked={items.checked} tableType={items.tableType}/>
                        </Stack>
                    ),
                },
            ]}
        />
    );
};