import { FunctionComponent, useEffect } from "react";
import { useIntl } from "react-intl";
import { Spinner, Stack, useTheme } from "@fluentui/react";
import { useJobContext } from "../../../JobPortalLayoutPage";
import { useGetPriorYearJob } from "../../../hooks";
import { ControlledTextField } from "../../../../../components";
import { useForm } from "react-hook-form";
import { DefaultFormSettings } from "../../../../../constants";

type AuditFeeFormProcedureTemplateProps = {}

type AuditFeeForm = {
	priorYearAmount?: string,
	currentYearAmount?: string
}

export const AuditFeeFormProceudreTemplate: FunctionComponent<AuditFeeFormProcedureTemplateProps> = ({ ...props }: AuditFeeFormProcedureTemplateProps) => {
	const {formatMessage, formatNumber} = useIntl();
	const theme = useTheme();

	const { job } = useJobContext();

	const { priorYearJob, isLoading } = useGetPriorYearJob(job.id);

	const { control, formState, setValue } = useForm<AuditFeeForm>({
		...DefaultFormSettings,
		defaultValues: {
			priorYearAmount: undefined,
			currentYearAmount: undefined
		},
	});

	useEffect(() => {
		if (job?.invoiceInfo?.auditFee) {
			setValue('currentYearAmount', formatNumber(job.invoiceInfo.auditFee, { style: 'currency', currency: 'USD' }))
		}

		if (priorYearJob?.invoiceInfo?.invoiceAmount) {
			setValue('priorYearAmount', formatNumber(priorYearJob.invoiceInfo.invoiceAmount, { style: 'currency', currency: 'USD' }))
		}
	}, [job, priorYearJob]);
	
	if (isLoading) {
		return <Spinner></Spinner>
	}

	const bodyTextStyles = { root: { color: theme.schemes?.default?.semanticColors.bodyText } };

	return (
		<>
			<Stack tokens={{ childrenGap: 16 }} style={{ width: '30%' }}>
				<Stack.Item>
					<Stack horizontal tokens={{ childrenGap: 16 }} horizontalAlign={'space-between'} verticalAlign="center">
						<Stack.Item styles={bodyTextStyles}>
							{formatMessage({ id: 'priorYear' })}
						</Stack.Item>
						<Stack.Item>
							<ControlledTextField name={'priorYearAmount'} 
							                     control={control}
							                     disabled
							/>
						</Stack.Item>
					</Stack>
				</Stack.Item>
				<Stack.Item>
					<Stack horizontal tokens={{ childrenGap: 16 }} horizontalAlign={'space-between'} verticalAlign="center">
						<Stack.Item styles={bodyTextStyles}>
							{formatMessage({ id: 'currentYear' })}
						</Stack.Item>
						<Stack.Item>
							<ControlledTextField name={'currentYearAmount'}
							                     control={control}
							                     disabled
							/>
						</Stack.Item>
					</Stack>
				</Stack.Item>
			</Stack>
		</>
	);
};
