import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { IColumn, SelectionMode, useTheme, Text } from '@fluentui/react';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { useBoolean } from '@fluentui/react-hooks';
import { DataTable, Modal } from 'components';
import { AddConnectionApproverForm } from './AddConnectionApproverForm';
import { useGetExternalConnectionsSettings } from "./hooks";
import { ConnectionApprover } from "./models";
import { RemoveApproevrButton } from "./RemoveApproverButton";

export const Approvers: FunctionComponent = () => {
    const theme = useTheme();
    const { formatMessage } = useIntl();
    const { connectionSettings, isLoading } = useGetExternalConnectionsSettings();
    
    const [isAddModalOpen, { toggle: toggleIsAddModalOpen }] = useBoolean(false);

    const [tableItems, setTableItems] = useState<ConnectionApprover[]>([]);

    useEffect(() => {
        setTableItems(connectionSettings?.approvers ?? [])
    }, [connectionSettings]);

    const columns: IColumn[] = [
        {
            key: 'email',
            fieldName: 'email',
            name: 'Approvers',
            minWidth: 200,
            onRender: (item: ConnectionApprover) => (<Text>{item.email}</Text>),
        },
        {
            key: 'action',
            name: '',
            fieldName: 'action',
            minWidth: 92,
            maxWidth: 92,
            onRender: (item: ConnectionApprover) => (<RemoveApproevrButton email={item.email} />),
        },
    ];

    const commandBarItems: ICommandBarItemProps[] = useMemo(
        () => [
            {
                key: 'add',
                text: formatMessage({ id: 'addConnectionApprover' }),
                iconProps: { iconName: 'add' },
                iconOnly: true,
                onClick: toggleIsAddModalOpen,
            },
        ],
        [toggleIsAddModalOpen, formatMessage]
    );

    return (
        <>
            <CommandBar
                items={[]}
                farItems={commandBarItems}
                styles={{ root: { padding: 0, borderBottom: `1px solid ${theme.palette.neutralLight}` } }}
            />
            <DataTable initialColumns={columns}
                       items={tableItems}
                       enableShimmer={isLoading}
                       selectionMode={SelectionMode.none} 
            />

            <Modal title={formatMessage({ id: 'addConnectionApprover' })} isOpen={isAddModalOpen} onDismiss={toggleIsAddModalOpen}>
                <AddConnectionApproverForm
                    onDismiss={toggleIsAddModalOpen}
                    onSubmit={(form) => {
                        toggleIsAddModalOpen();
                    }}
                />
            </Modal>
            
            
        </>
    );
};
