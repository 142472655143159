import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { ProceduresDataTable } from '../../../../../ProceduresDataTable';
import { AuditAcceptanceSection } from '../AuditAcceptanceSection';
import { ControlledTextField, ITableColumn } from '../../../../../../../../components';
import { ActionButton, mergeStyleSets, Stack, Text, useTheme } from '@fluentui/react';
import { TableType } from '../../../../../../../../enums';
import { JobComment } from '../../../../../JobComment';
import { AuditAcceptanceIssuesItem } from '../../interfaces';
import { useFieldArray, useForm } from 'react-hook-form';
import { DefaultFormSettings } from '../../../../../../../../constants';
import { Control } from 'react-hook-form/dist/types/form';
import { useSaveIssueItem } from './hooks';
import { useJobContext } from '../../../../../../JobPortalLayoutPage';
import { useTabContext } from 'pages/JobPortal';
import { logger } from 'services';

type AuditAcceptanceIssuesProps = {
    items: AuditAcceptanceIssuesItem[];
};

type AuditAcceptanceIssuesFormItem = Pick<AuditAcceptanceIssuesItem, 'notes' | 'hasComments'> & { itemId: number };

export const AuditAcceptanceIssues: FunctionComponent<AuditAcceptanceIssuesProps> = ({ items, ...props }: AuditAcceptanceIssuesProps) => {
    const { formatMessage } = useIntl();
    const theme = useTheme();

    const { job } = useJobContext();
    const { isTabEnabled } = useTabContext();
    const { save, isSaving } = useSaveIssueItem();

    const { control, setValue, handleSubmit } = useForm<{ items: AuditAcceptanceIssuesFormItem[] }>({
        ...DefaultFormSettings,
    });
    const { fields } = useFieldArray({
        control,
        name: 'items',
    });

    const [isCreating, setIsCreating] = useState(isSaving);

    useEffect(() => {
        const formItems = items
            .sort((a: AuditAcceptanceIssuesItem, b: AuditAcceptanceIssuesItem) => a.id - b.id)
            .map(
                (i: AuditAcceptanceIssuesItem): AuditAcceptanceIssuesFormItem => ({
                    notes: i.notes,
                    itemId: i.id,
                    hasComments: i.hasComments,
                })
            );
        setValue('items', formItems);
    }, [items, items.length, setValue]);

    const onBlur = (index: number) => {
        handleSubmit((form: { items: AuditAcceptanceIssuesFormItem[] }) => {
            save({
                id: form.items[index].itemId,
                notes: form.items[index].notes,
                jobId: job.id,
                fundId: job.fund.id,
            });
        })();
    };

    const onAddItem = () => {
        setIsCreating(true);
        save(
            {
                id: 0,
                notes: '',
                jobId: job.id,
                fundId: job.fund.id,
            },
            {
                onSuccess: (resp: any) => {
                    items.push({ ...resp.data });
                },
                onSettled: () => {
                    setIsCreating(false);
                },
            }
        );
    };

    const columns: ITableColumn[] = [
        {
            key: 'actions',
            name: '',
            fieldName: 'actions',
            onRender: (item: AuditAcceptanceIssuesFormItem) => (
                <Stack horizontal horizontalAlign={'center'}>
                    <JobComment itemId={item.itemId} tableType={TableType.AuditAcceptanceIssuesTable} hasComments={item.hasComments} />
                </Stack>
            ),
            minWidth: 30,
            maxWidth: 30,
        },
        {
            key: 'notes',
            name: formatMessage({ id: 'notes' }),
            minWidth: 180,
            fieldName: 'notes',
            onRender: (field, index) =>
                index !== undefined && (
                    <AuditAcceptanceIssuesCell item={field} index={index} name={'notes'} control={control} onBlur={onBlur} />
                ),
        },
    ];

    return (
        <AuditAcceptanceSection name={formatMessage({ id: 'auditAcceptanceIssues' })} bodrered={false}>
            <Stack tokens={{ childrenGap: 16 }}>
                <Stack.Item>
                    <ProceduresDataTable items={fields} columns={columns} hideIfEmpty={false} />
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal horizontalAlign={'center'}>
                        <Stack.Item>
                            <ActionButton
                                styles={{ textContainer: { color: theme.schemes?.default?.semanticColors.bodyText } }}
                                iconProps={{ iconName: 'Add' }}
                                onClick={onAddItem}
                                disabled={isCreating || !isTabEnabled}>
                                {formatMessage({ id: 'clickToAddRow' })}
                            </ActionButton>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>
        </AuditAcceptanceSection>
    );
};

type AuditAcceptanceIssuesCellProps = {
    item: AuditAcceptanceIssuesFormItem;
    index: number;
    control: Control<{ items: AuditAcceptanceIssuesFormItem[] }>;
    name: keyof AuditAcceptanceIssuesFormItem;
    onBlur: (index: number) => void;
};

const AuditAcceptanceIssuesCell: FunctionComponent<AuditAcceptanceIssuesCellProps> = ({
    index,
    control,
    name,
    onBlur,
    ...props
}: AuditAcceptanceIssuesCellProps) => {
    const { isTabEnabled } = useTabContext();
    const [isEditMode, setEditMode] = useState<boolean>(false);

    const classNames = mergeStyleSets({
        stack: {
            width: '100%',
            height: '100%',
            'align-items': 'start',
            'justify-content': 'center',

            '> .ms-TextField': {
                width: 'inherit',
            },
        },
    });

    const onEditClick = useCallback(() => {
        logger.debug('[AUDIT_ACCEPT:EDIT]', isTabEnabled);

        if (!isTabEnabled) return;

        if (!isEditMode) setEditMode(true);
    }, [isTabEnabled, isEditMode]);

    return (
        <Stack grow className={classNames.stack} onClick={onEditClick}>
            {isEditMode ? (
                <ControlledTextField
                    name={`items.${index}.${name}`}
                    control={control}
                    disabled={!isTabEnabled}
                    onBlur={() => {
                        onBlur(index);
                        setEditMode(false);
                    }}
                />
            ) : (
                <Text>{control._formValues.items[index][name]}</Text>
            )}
        </Stack>
    );
};
