import * as React from 'react';
import {FunctionComponent, ReactNode} from 'react';
import {
    ContextualMenu,
    FontWeights,
    IconButton,
    IIconProps,
    mergeStyleSets,
    Modal as ModalFluentUI,
    Stack,
    Text,
    useTheme
} from '@fluentui/react';
import {useId} from '@fluentui/react-hooks';
import {IDragOptions, IModalProps} from '@fluentui/react/lib/components/Modal/Modal.types';
import {Alignment} from '@fluentui/react/lib/components/Stack/Stack.types';

interface Props extends IModalProps {
    title?: string;
    isDraggable?: boolean;
    onDismiss?: () => any;
    fullWidth?: boolean;
    maxHeight?: boolean;
    minWidth?: number | string;
    width?: number | string;
    resize?: 'both' | undefined;
}

const defaultDragOptions: IDragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
    dragHandleSelector: '.ms-Modal-scrollableContent .modal-title-container',
};

export const Modal: FunctionComponent<Props> = ({title, isDraggable, children, ...props}: Props) => {
    const titleId = useId('modal-title');
    const theme = useTheme();

    const dragOptions = isDraggable ? { ...defaultDragOptions, ...props.dragOptions } : undefined;

    return (
        <ModalFluentUI
            {...props}
            titleAriaId={titleId}
            dragOptions={dragOptions}
            styles={{
                main: {
                    resize: props.resize || 'inherit',
                    minWidth: props.minWidth || '50vw',
                    maxWidth: '95vw',
                    minHeight: props.maxHeight ? '90%' : 'auto',
                    width: props.width || (props.fullWidth ? '96vw' : 1000),
                    background: theme.palette.neutralLight,
                },
                scrollableContent: props.resize ? {height: 'inherit'} : {maxHeight: 'calc(100vh - 50px)'},
            }}>
            <Stack styles={{root: props.resize ? {height: 'inherit', display: 'flex'} : {}}} tokens={{childrenGap: 16}}>
                <ModalTitle isDraggable={isDraggable} className={'modal-title-container'} title={title || ''} onDismiss={props.onDismiss}/>

                <Stack.Item styles={{root: {
                    padding: '0 16px 16px',
                    height: props.resize ? 'inherit' : 'auto',
                    display: props.resize ? 'flex' : 'initial'
                }}}>{children}</Stack.Item>
            </Stack>
        </ModalFluentUI>
    );
};

type ModalTitleProps = {
    title?: string;
    isDraggable?:boolean; 
    className?: string;
    onDismiss?: () => void;

}

export const ModalTitle: FunctionComponent<ModalTitleProps> = (props: ModalTitleProps) => {

    const titleId = useId('modal-title');
    const theme = useTheme();
    const cancelIcon: IIconProps = {iconName: 'Cancel'};
    const contentStyles = mergeStyleSets({
        header: [
            theme.fonts.large,
            {
                boxShadow: theme.effects.elevation4,
                flex: '1 1 auto',
                borderTop: `4px solid ${theme.palette.themePrimary}`,
                color: theme.schemes?.default?.semanticColors.bodyText,
                display: 'flex',
                alignItems: 'center',
                padding: '8px 16px',
                background: theme.palette.white,
                maxHeight: '52px',
                cursor: props.isDraggable ? 'all-scroll' : 'auto',
            },
        ],
        heading: {
            color: theme.schemes?.default?.semanticColors.bodyText,
            fontWeight: FontWeights.regular,
            fontSize: 'inherit',
            margin: '0',
        }
    });


    return <Stack
        horizontal
        horizontalAlign={props.title ? "space-between" : "end"}
        verticalAlign='center'
        tokens={{childrenGap: 8, padding: '8px 16px'}}
        className={`${contentStyles.header} ${props.className}`}>
        {props.title && (<Text variant='large' theme={theme.schemes?.default} id={titleId} className={contentStyles.heading}>
            {props.title}
        </Text>)}

        <IconButton iconProps={cancelIcon} onClick={props.onDismiss}/>
    </Stack>
}

interface ModalFooterProps {
    horizontalAlign?: Alignment;
    children: ReactNode;
}

export const ModalFooter: FunctionComponent<ModalFooterProps> = ({horizontalAlign, children}: ModalFooterProps) => {
    const theme = useTheme();
    return (
        <Stack
            horizontal
            horizontalAlign={horizontalAlign}
            tokens={{childrenGap: 16, padding: '16px'}}
            styles={{root: {background: theme.palette.white, margin: '0 -16px -16px'}}}>
            {children}
        </Stack>
    );
};
