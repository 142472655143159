import {
    IconButton,
    IDropdownOption,
    Label,
    Link,
    PrimaryButton,
    Spinner,
    SpinnerSize,
    Stack,
    useTheme
} from '@fluentui/react';
import {FunctionComponent, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {IAttachment, IJobCommentDocument} from '../../hooks';
import {ControlledDropdown} from 'components';
import {DefaultFormSettings} from 'constants/forms';
import {useForm} from 'react-hook-form';
import {useGetFile} from "../../../../hooks/useGetFile";

interface IJobCommentLinkedDocumentsProps {
    selected: IAttachment[];
    setSelected: React.Dispatch<React.SetStateAction<IAttachment[]>>;
    documents?: IJobCommentDocument[];
    attachments?: IAttachment[];
    isLoading?: boolean;
    disabled?: boolean;
}

interface ILinkedDocumentsForm {
    linkedDocuments?: number[];
}

export const JobCommentLinkedDocuments: FunctionComponent<IJobCommentLinkedDocumentsProps> = ({
                                                                                                  documents,
                                                                                                  attachments,
                                                                                                  isLoading,
                                                                                                  disabled,
                                                                                                  selected,
                                                                                                  setSelected
                                                                                              }) => {
    const {formatMessage} = useIntl();
    const theme = useTheme();
    const {getFile, isExportDownloading} = useGetFile();

    const [downloadingId, setDownloadingId] = useState<number>();

    const options: IDropdownOption[] = useMemo(() => (attachments || []).map((attachment) => ({
        key: attachment.id,
        text: attachment.fileName,
        selected: documents?.some(d => d.attachmentId === attachment.id)
    })).sort((a, b) => a.text.localeCompare(b.text)),
        [attachments, documents]);

    const {control, handleSubmit} = useForm<ILinkedDocumentsForm>({
        ...DefaultFormSettings,
        defaultValues: {
            linkedDocuments: options.filter(option => documents?.some(d => option.key === d.attachmentId)).map(option => +option.key),
        },
    });

    const onAdd = (data: ILinkedDocumentsForm) => {
        const {linkedDocuments} = data;
        const linkedAttachments = attachments?.filter(a => linkedDocuments?.some(d => d === a.id)) || [];
        setSelected(linkedAttachments);
    };

    const onRemove = (attachmentId: number) => {
        setSelected(prev => prev.filter(s => s.id !== attachmentId));
    };

    return (
        <Stack tokens={{childrenGap: 16}}>
            <Label styles={{root: {color: theme.schemes?.default?.semanticColors.bodyText}}}>{formatMessage({id: 'linkedDocuments'})}</Label>
            <Stack horizontal tokens={{childrenGap: 16}}>
                <Stack horizontal tokens={{childrenGap: 16}}>
                    <Stack styles={{root: {minWidth: 350, maxWidth: 350}}}>
                        <ControlledDropdown placeholder={formatMessage({id: 'chooseDocument'})} multiSelect
                                            name='linkedDocuments' options={options} control={control}
                                            disabled={isLoading || disabled}/>
                    </Stack>
                    <PrimaryButton onClick={handleSubmit(onAdd)} text={formatMessage({id: 'add'})} disabled={disabled}/>
                </Stack>
                <Stack grow tokens={{childrenGap: 16}}
                       styles={{root: {paddingLeft: 16, borderLeft: `1px solid ${theme.palette.neutralDark}`}}}>
                    {selected.map((s, idx) => (
                        <Stack horizontal tokens={{childrenGap: 16}} verticalAlign='center'
                               key={`linked-document-${idx}-${s.id}`}>
                            <IconButton
                                iconProps={{iconName: 'Remove'}}
                                styles={{root: {color: theme.semanticColors.errorIcon}}}
                                onClick={() => onRemove(s.id)}
                                disabled={disabled}
                            />
                            <Link underline style={{ color: theme.schemes?.default?.palette.blue }}
                                onClick={() => {
                                    setDownloadingId(s.id);
                                    getFile({url: s.downloadUrl, fileName: s.fileName}, { onSettled: () => setDownloadingId(undefined) })
                                }}>{s.fileName}</Link>
                            {isExportDownloading && downloadingId === s.id && (
                                <Spinner label={formatMessage({id: 'downloading'})} labelPosition={"right"}
                                         size={SpinnerSize.small}/>)}
                        </Stack>
                    ))}
                </Stack>
            </Stack>
        </Stack>
    );
};