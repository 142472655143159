import { FunctionComponent } from "react";
import { IIChecklistAnswer } from "../../interfaces";
import { AuditAcceptanceSection } from "../AuditAcceptanceSection";
import { useIntl } from "react-intl";
import { mergeStyleSets, Stack, useTheme } from "@fluentui/react";
import { SanitizedText } from "../../../../../../../../components";

type RequiredDocumentsProps = {
	items: IIChecklistAnswer[]
}

export const RequiredDocuments: FunctionComponent<RequiredDocumentsProps> = ({ items, ...props }: RequiredDocumentsProps) => {
	const { formatMessage } = useIntl();
	const theme = useTheme();

	const classNames = mergeStyleSets({
		documentsListContainer: {
			padding: 16,
			paddingLeft: 32
		},

		documentsListItem: {
			display: 'list-item'
		}
	});

	const bodyTextStyles = { root: { color: theme.schemes?.default?.semanticColors.bodyText } };
	
	return (
		<AuditAcceptanceSection name={formatMessage({id: 'minimumRequiredDocuments'})}>
			<Stack>
				<Stack.Item styles={bodyTextStyles}>
					{formatMessage({id: 'requiredDocumentsMessage'})}
				</Stack.Item>
				<Stack.Item>
					<Stack className={classNames.documentsListContainer} tokens={{childrenGap: 4}}>
						{items.filter((i: IIChecklistAnswer) => i.canShowQ && i.answerText).map((i: IIChecklistAnswer, idx: number) => {
							return (
								<Stack.Item key={'req-doc-item-' + idx} className={classNames.documentsListItem}>
									<SanitizedText data={i.answerText} />
								</Stack.Item>
							)
						})}
					</Stack>
				</Stack.Item>
			</Stack>
		</AuditAcceptanceSection>
	);
};
