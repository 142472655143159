import React from 'react';
import axios from 'axios';
import { useMutation } from 'react-query';
import { commonHeaders, Context, queryClient } from 'utils';

interface IUpdateAttachmentUnmarkAsPermProps {
  id: number;
  jobId: number;
  itemId: number;
  year: number;
}

function useUpdateAttachmentUnmarkAsPerm() {
    const context = React.useContext(Context);

    const { mutate: unmarkAsPerm, isLoading, isSuccess } = useMutation(({ jobId, itemId, id, year }: IUpdateAttachmentUnmarkAsPermProps) =>
        axios.put(`${context?.config?.annotationsUrl}/api/1.0/jobs/${jobId}/items/${itemId}/attachments/${id}/unmarkAsPerm?year=${year}`, null, commonHeaders()),
        {
            onSuccess: () => { 
                queryClient.invalidateQueries('getAttachments');
                queryClient.invalidateQueries('getDisplayDocuments');
                queryClient.invalidateQueries('getDisplayAllDocuments')
            },
        }
    );

    return { unmarkAsPerm, isLoading, isSuccess };
}

export { useUpdateAttachmentUnmarkAsPerm };